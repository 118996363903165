// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAddresBook = createAsyncThunk('appEmail/getAddressBook', async () => {
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/api/auth/address-book/get-contacts`
	)
	return {
		data: response.data
	}
})

export const getFolders = createAsyncThunk('appEmail/getFolders', async ({ account }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/folders`, {
		params: { account: account.username }
	})
	// console.log('Dispatched folders: ', response.data)
	return {
		data: response.data
	}
})

export const getMails = createAsyncThunk('appEmail/getMails', async ({ account, folder }) => {
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/api/mailbox/folders/${folder}`,
		{
			params: { account: account.username }
		}
	)
	// console.log('Dispatched mails: ', response.data)
	return {
		params: { account, folder },
		data: response.data
	}
})

export const updateMails = createAsyncThunk(
	'appEmail/updateMails',
	async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
		const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
		await dispatch(getMails(getState().email.params))
		return {
			emailIds,
			dataToUpdate,
			data: response.data
		}
	}
)

export const updateMailLabel = createAsyncThunk(
	'appEmail/updateMailLabel',
	async ({ emailIds, label }, { dispatch, getState }) => {
		const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
		await dispatch(getMails(getState().email.params))
		return response.data
	}
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
	const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
	return response.data
})

export const selectCurrentMail = createAsyncThunk(
	'appEmail/selectCurrentMail',
	async ({ account, folder, uid }) => {
		console.log(`Dispatching email for ${account.username} from ${folder}`)
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/mailbox/email/read/${folder}/${uid}`,
			{
				params: { account: account.username }
			}
		)
		console.log('Dispatched email: ', response.data)
		return response.data
	}
)

export const moveMails = createAsyncThunk('appEmail/moveMails', async ({ account, mails, to }) => {
	console.log('Mails to move: ', mails)
	console.log('Destination folder: ', to)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/move`, {
		account: account.username,
		mails,
		to
	})
	return response.data
})

/*export const deleteMails = createAsyncThunk('appEmail/delteMails', async (uids) => {
	console.log('Uids to delete: ', uids)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/delete`, { uids })
	return response.data
})*/

export const flagMails = createAsyncThunk(
	'appEmail/flagMails',
	async ({ account, uids, folder }) => {
		console.log('Uids to flag: ', uids)
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/mailbox/emails/flag`,
			{
				account: account.username,
				uids,
				folder
			}
		)
		console.log('Flagged: ', response)
		return response.data
	}
)

export const appEmailSlice = createSlice({
	name: 'appEmail',
	initialState: {
		folders: [],
		folder: null,
		mails: [],
		params: {},
		emailsMeta: {},
		selectedMails: {},
		currentMail: null,
		addressBook: []
	},
	reducers: {
		selectMail: (state, action) => {
			const { messageId, folder, uid } = action.payload
			const selectedMails = state.selectedMails
			if (!selectedMails[messageId]) {
				selectedMails[messageId] = { folder, uid }
			} else {
				delete selectedMails[messageId]
			}
			state.selectedMails = selectedMails
		},
		selectAllMail: (state, action) => {
			const selectAllMailsArr = []
			if (action.payload) {
				selectAllMailsArr.length = 0
				state.mails.forEach((mail) => selectAllMailsArr.push(mail.uid))
			} else {
				selectAllMailsArr.length = 0
			}
			state.selectedMails = selectAllMailsArr
		},
		resetSelectedMail: (state) => {
			state.selectedMails = []
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAddresBook.fulfilled, (state, action) => {
				console.log('Addresses dispatched...')
				state.addressBook = action.payload.data
			})
			.addCase(getFolders.fulfilled, (state, action) => {
				console.log('Folders dispatched...')
				state.folders = action.payload.data
			})
			.addCase(getMails.fulfilled, (state, action) => {
				console.log('Emails dispatched...')
				let currMail = null
				if (state.currentMail !== null && state.currentMail !== undefined) {
					currMail = action.payload.data.emails.find(
						(i) => i.uid === state.currentMail.uid
					)
				}
				state.currentMail = currMail
				state.params = action.payload.params
				state.folder = action.payload.data.folder
				state.mails = action.payload.data.emails
				state.emailsMeta = action.payload.data.emailsMeta
			})
			.addCase(flagMails.fulfilled, (state, action) => {
				const ids = Object.keys(action.payload)
				state.mails.forEach((email) => {
					if (ids.includes(email.messageId)) {
						Object.assign(email, { flags: action.payload[email.messageId] })
					}
				})
			})
			.addCase(moveMails.fulfilled, (state, action) => {
				state.mails.forEach((email) => {
					if (action.payload.moved.includes(email.messageId)) {
						const array = [...state.mails] // make a separate copy of the array
						const index = array.indexOf(email)
						if (index !== -1) {
							array.splice(index, 1)
							state.mails = array
						}
					}
				})

				const from = Object.keys(action.payload.from)
				state.folders.forEach((folder) => {
					if (from.includes(folder.path)) {
						Object.assign(folder, { exists: action.payload.from[folder.path].exists })
					}
					if (action.payload.to.path === folder.path) {
						Object.assign(folder, { exists: action.payload.to.exists })
					}
				})
			})
			.addCase(updateMails.fulfilled, (state, action) => {
				function updateMailData(email) {
					Object.assign(email, action.payload.dataToUpdate)
				}
				state.mails.forEach((email) => {
					if (action.payload.emailIds.includes(email.uid)) {
						updateMailData(email)
					}
				})
			})
			.addCase(paginateMail.fulfilled, (state, action) => {
				const data = action.payload
				const dataIndex = state.mails.findIndex((i) => i.uid === data.uid)
				dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
				dataIndex === state.mails.length - 1
					? (data.hasNextMail = false)
					: (data.hasNextMail = true)
				state.currentMail = data
			})
			.addCase(selectCurrentMail.fulfilled, (state, action) => {
				state.currentMail = action.payload
			})
	}
})

export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions

export default appEmailSlice.reducer
